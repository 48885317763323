import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer_content">
        <span className="footer_title">
          For help or questions please contact the JIREH SALI LOGISTICS INC.
          representative or{" "}
          <a href="mailto:Jirehsalilogistics@outlook.com">
            Carriers.Jirehsalilogistics@outlook.com
          </a>
          <br />
          &copy; 2024{" "}
          <a href="http://www.registrymonitoring.com">
            Registry Monitoring Insurance Services, Inc.
          </a>
        </span>
        <br />
        <div className="privacy">
          <label id="PrivacyPolicy" style={{ fontWeight: "400" }}>
            <a href="https://truckstop.com/privacy-policy/">
              RMIS Privacy Policy
            </a>
          </label>
          <label style={{ fontWeight: "400px" }}>
            &nbsp;|&nbsp;
            <a href="https://rmis.com/dont-sell-my-personal-information/">
              Do Not Sell My Personal Information
            </a>
          </label>
          <div className="status-container">
            <div className="status-dot"></div>
            <label className="rmis-status">
              RMIS Status - All Systems Operational
            </label>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
