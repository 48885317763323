// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  color: #151b26;
  display: flex; 
  flex-direction: column; 
}


.carrier_page, .verify_page, .content_page {
  display: flex; 
  flex-direction: column; 
  min-height: calc(100vh - 150px); 
}

.carrier_main, .verify_main, .main_content {
  flex: 1 1;
  padding: 20px; 
  box-sizing: border-box; 
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,0CAA0C;EAC1C,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,sBAAsB;AACxB;;;AAGA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;AACxB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap\");\nhtml, body {\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  box-sizing: border-box;\n  font-family: \"Lato\", sans-serif !important;\n  font-weight: 400;\n  font-style: normal;\n  color: #151b26;\n  display: flex; \n  flex-direction: column; \n}\n\n\n.carrier_page, .verify_page, .content_page {\n  display: flex; \n  flex-direction: column; \n  min-height: calc(100vh - 150px); \n}\n\n.carrier_main, .verify_main, .main_content {\n  flex: 1;\n  padding: 20px; \n  box-sizing: border-box; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
