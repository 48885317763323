import React from "react";
import "./emailField.css";
import { useDispatch } from "react-redux";
import { changeCurrentStatus, changeSocialEmail, changeSocialSubTitle, changeSocialTitle } from "../../store/slices/socialLogin/socialLoginSlice";

const EmailField = () => {
    const dispatch = useDispatch();
    const goToPassword = (e) => {
        const [email] = e.target;
      e.preventDefault();
      dispatch(changeSocialTitle('Hi User'))
        dispatch(changeSocialEmail(email.value))
        dispatch(changeSocialSubTitle(email.value))
        dispatch(changeCurrentStatus('LOGIN_PASSWORD'))
  };
  return (
    <>
      <form onSubmit={goToPassword}>
        <div className="email-login-main-accounts">
          <div className="input-container">
            <input
              type="text"
              id="input"
              className="input-field"
              placeholder="Your Text"
              required
            />
            <label htmlFor="input" className="input-label">
              Email or phone
            </label>
            <div className="forgot-email">Forgot email?</div>
          </div>
          <div className="email_notification">
            <div>
              Before using this app, you can review Truckstop’s{" "}
              <a href="https://truckstop.com/privacy-policy/" target="_blank">
                privacy policy
              </a>{" "}
              and terms of service.
            </div>
          </div>{" "}
          <div className="email-next_step">
            <button className="create-account">Create account</button>
            <button type="submit" className="Next">
              Next
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default EmailField;

// <div className='email_field'>

// </div>
