import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectRegStatus } from "../../store/slices/registration/registrationSlice";
import { useNavigate } from "react-router-dom";
import "./verifyPage.css";
import Footer from "../../components/Footer/Footer";
import { selectUsers } from "../../store/slices/users/userSlice";

const VerifyPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const { registrationStatus } = useSelector(selectRegStatus);
  const { currentUser } = useSelector(selectUsers);

  const navigate = useNavigate();
  useEffect(() => {
    if (!registrationStatus) {
      navigate("/_c/std/reg/DOTLookupStdV33.aspx");
    } else {
      setIsLoaded(true);
    }
  }, []);

  if (!isLoaded) return;

  isChecked && <div></div>;
  return (
    <>
      <div className="verify_page">
        <div className="verify_main">
          {!isConfirmed ? (
            <div className="confirm_company">
              <div className="confirm_body">
                <div className="modal_header">
                  <div className="modal_title">
                    Please confirm your name and address to proceed:
                  </div>
                </div>
                <div className="modal_body">
                  <div className="modal_body_info first_modal">
                    <div className="company_title">
                      <div>Company Name:</div>
                    </div>
                    <div className="company_info">
                      <div>
                        <span className="company_name">
                          {currentUser["U.S.-DOT-INFORMATION"]["Legal-Name"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="modal_body_info second_modal">
                    <div className="company_title">
                      <div>Address:</div>
                    </div>
                    <div className="company_info">
                      <div>
                        <span className="company_address">
                          {currentUser["U.S.-DOT-INFORMATION"]["Address"]}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal_footer">
                  <div className="modal_buttons">
                    <input
                      type="submit"
                      value="Cancel"
                      className="cancelBtn"
                      onClick={() => navigate("/v3")}
                    />
                    <input
                      type="submit"
                      value="Confirm"
                      className="confirmBtn"
                      onClick={() => setIsConfirmed(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div>
            <div className="carrier_prequalification">
              <span>Carrier Prequalification</span>
            </div>
            <div className="carrier_body">
              <div className="step_buttons">
                <input
                  className="goBack"
                  type="submit"
                  value="Please click here to search again"
                  onClick={() => navigate("/v3")}
                />
                <div id="verify_submit">
                  <input
                    type="submit"
                    value="Go To Next Step"
                    onClick={() => navigate("/authorize")}
                  />
                </div>
              </div>
              <div className="verify_notification">
                <label>
                  Based on the carrier ID provided, your company's information
                  and the results of your prequalification using the Route King
                  Logistics LLC requirements are below.
                </label>
              </div>

              <div className="data">
                <div className="requirements">
                  <div className="requirements_header">
                    <label style={{ textTransform: "uppercase" }}>
                      Route King Logistics LLC Requirements
                    </label>
                  </div>
                  <div className="requirements_info">
                    <div className="requirements_info_title">
                      <div className="rule">
                        <div className="requirement_row">
                          <div className="requirement_title">Rule</div>
                          <div className="result-container">
                            <span className="result requirement_title_info">
                              Result
                            </span>
                            <span className="comment requirement_title_info">
                              Comment
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="requirement_row">
                        <div className="requirement_title">Op Status:</div>
                        <div>
                          <label className="requirements_status requirement_title_info">
                            {
                              currentUser[
                                "Route-King-Logistics-LLC-Requirements"
                              ]["Op-Status"]
                            }
                          </label>
                        </div>
                      </div>
                      <div className="requirement_row">
                        <div className="requirement_title">DOT Auth:</div>
                        <div>
                          <label className="requirements_status requirement_title_info">
                            {
                              currentUser[
                                "Route-King-Logistics-LLC-Requirements"
                              ]["DOT-Auth"]
                            }
                          </label>
                        </div>
                      </div>
                      <div className="requirement_row">
                        <div className="requirement_title">DOT Auth Time:</div>
                        <div>
                          <label className="requirements_status requirement_title_info">
                            {
                              currentUser[
                                "Route-King-Logistics-LLC-Requirements"
                              ]["DOT-Auth-Time"]
                            }
                          </label>
                        </div>
                      </div>
                      <div className="requirement_row">
                        <div className="requirement_title">DOT Safety:</div>
                        <div>
                          <label className="requirements_status requirement_title_info">
                            {
                              currentUser[
                                "Route-King-Logistics-LLC-Requirements"
                              ]["DOT-Safety"]
                            }
                          </label>
                        </div>
                      </div>
                      <div className="requirement_row">
                        <div className="requirement_title">
                          Intrastate DOTNumber:
                        </div>
                        <div>
                          <label className="requirement_title_info">
                            {
                              currentUser[
                                "Route-King-Logistics-LLC-Requirements"
                              ]["Intrastate-DOTNumber"]
                            }
                          </label>
                        </div>
                      </div>
                      <div className="requirement_row last_requirement_row">
                        <div className="requirement_title">Other:</div>
                        <div>
                          <label className="requirements_status requirement_title_info">
                            {
                              currentUser[
                                "Route-King-Logistics-LLC-Requirements"
                              ]["Other"]
                            }
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dot_and_authority_information">
                  <div className="dot_information">
                    <div className="dot_header">
                      <label style={{ textTransform: "uppercase" }}>
                        U.S. Dot Information
                      </label>
                    </div>
                    <div className="dot_info">
                      <div className="dot_info_title">
                        <div className="dot_row">
                          <div className="dot_title">Docket Number:</div>
                          <div>
                            <span className="dot_title_info">
                              {
                                currentUser["U.S.-DOT-INFORMATION"][
                                  "Docket-Number"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="dot_row">
                          <div className="dot_title">Legal Name:</div>
                          <div>
                            <span className="dot_title_info">
                              {
                                currentUser["U.S.-DOT-INFORMATION"][
                                  "Legal-Name"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="dot_row">
                          <div className="dot_title">DBA Name:</div>
                          <div>
                            <span className="dot_title_info">
                              {currentUser["U.S.-DOT-INFORMATION"]["DBA-Name"]}
                            </span>
                          </div>
                        </div>
                        <div className="dot_row dot_address">
                          <div className="dot_title address">Address:</div>
                          <div>
                            <span className="dot_title_info addres_info">
                              {/* 6819 W WATERFORD RD, HARTFORD, <br /> WI 53027 */}
                              {currentUser["U.S.-DOT-INFORMATION"]["Address"]}
                            </span>
                          </div>
                        </div>
                        <div className="dot_row">
                          <div className="dot_title">Operating Status:</div>
                          <div>
                            <span className="dot_title_info authorized_status">
                              {
                                currentUser["U.S.-DOT-INFORMATION"][
                                  "Operating-Status"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="dot_row">
                          <div className="dot_title">Out of Service Date:</div>
                          <div>
                            <span className="dot_title_info">
                              {
                                currentUser["U.S.-DOT-INFORMATION"][
                                  "Out of Service Date"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="authority_information">
                    <div className="authority_header">
                      <label style={{ textTransform: "uppercase" }}>
                        Authority Information
                      </label>
                    </div>
                    <div className="authority_info">
                      <div className="authority_info_title">
                        <div className="authority_row">
                          <div className="authority_title">
                            Common Authority:
                          </div>
                          <div>
                            <span className="authority_title_info">
                              {
                                currentUser["Authority-Information"][
                                  "Common-Authority"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="authority_row">
                          <div className="authority_title">
                            Contract Authority:
                          </div>
                          <div>
                            <span className="authority_title_info">
                              {" "}
                              {
                                currentUser["Authority-Information"][
                                  "Contract-Authority"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="authority_row">
                          <div className="authority_title">
                            Broker Authority:
                          </div>
                          <div>
                            <span className="authority_title_info">
                              {" "}
                              {
                                currentUser["Authority-Information"][
                                  "Broker-Authority"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="authority_row">
                          <div className="authority_title">
                            Original Authority Grant Date:
                          </div>
                          <div>
                            <span className="authority_title_info">
                              {
                                currentUser["Authority-Information"][
                                  "Original-Authority-Grant-Date"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="authority_row">
                          <div className="authority_title">
                            Authority Grant Date:
                          </div>
                          <div>
                            <span className="authority_title_info">
                              {
                                currentUser["Authority-Information"][
                                  "Authority-Grant-Date"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="authority_row">
                          <div className="authority_title">
                            Authority Reinstate Date:
                          </div>
                          <div>
                            <span className="authority_title_info">
                              {
                                currentUser["Authority-Information"][
                                  "Authority-Reinstate-Date"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="authority_row">
                          <div className="authority_title">
                            Authority Revocation Date:
                          </div>
                          <div>
                            <span className="authority_title_info">
                              {
                                currentUser["Authority-Information"][
                                  "Authority-Revocation-Date"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="authority_row">
                          <div className="authority_title">
                            Original Broker Authority Grant Date:
                          </div>
                          <div>
                            <span className="authority_title_info">
                              {
                                currentUser["Authority-Information"][
                                  "Original-Broker-Authority-Grant-Date"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="authority_row">
                          <div className="authority_title">
                            Broker Authority Grant Date:
                          </div>
                          <div>
                            <span className="authority_title_info">
                              {
                                currentUser["Authority-Information"][
                                  "Broker-Authority-Grant-Date"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="authority_row">
                          <div className="authority_title">
                            Broker Authority Reinstate Date:
                          </div>
                          <div>
                            <span className="authority_title_info">
                              {
                                currentUser["Authority-Information"][
                                  "Broker-Authority-Reinstate-Date"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="authority_row">
                          <div className="authority_title">
                            Broker Authority Revocation Date:
                          </div>
                          <div>
                            <span className="authority_title_info">
                              {
                                currentUser["Authority-Information"][
                                  "Broker-Authority-Revocation-Date"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="safety_and_drivers_information">
                  <div className="safety_information">
                    <div className="safety_header">
                      <label style={{ textTransform: "uppercase" }}>
                        Carrier Safety Rating
                      </label>
                    </div>
                    <div className="safety_info">
                      <div className="safety_info_title">
                        <div className="safety_row">
                          <div className="safety_title">Rating Date:</div>
                          <div>
                            <span className="safetyt_title_info">
                              {
                                currentUser["Carrier-Safety-Rating"][
                                  "Rating-Date"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="safety_row">
                          <div className="safety_title">Rating:</div>
                          <div>
                            <span className="safety_title_info">
                              {currentUser["Carrier-Safety-Rating"]["Rating"]}
                            </span>
                          </div>
                        </div>
                        <div className="safety_row">
                          <div className="safety_title">Review Date:</div>
                          <div>
                            <span className="safety_title_info">
                              {
                                currentUser["Carrier-Safety-Rating"][
                                  "Review-Date"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="safety_row">
                          <div className="safety_title review_type">
                            Review Type:
                          </div>
                          <div>
                            <span className="safety_title_info">
                              {
                                currentUser["Carrier-Safety-Rating"][
                                  "Review-Type"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="drivers_information">
                    <div className="drivers_header">
                      <label style={{ textTransform: "uppercase" }}>
                        Drivers
                      </label>
                    </div>
                    <div className="drivers_info">
                      <div className="drivers_info_title">
                        <div className="drivers_row">
                          <div className="drivers_title">
                            Interstate Drivers &lt;100 Miles:
                          </div>
                          <div>
                            <span className="drivers_title_info">
                              {
                                currentUser["Drivers"][
                                  "Interstate-Drivers-<100-Miles"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title">
                            Interstate Drivers 100+ Miles:
                          </div>
                          <div>
                            <span className="drivers_title_info">
                              {
                                currentUser["Drivers"][
                                  "Interstate-Drivers-100+-Miles"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title">
                            Total Interstate Drivers:
                          </div>
                          <div>
                            <span className="drivers_title_info">
                              {" "}
                              {
                                currentUser["Drivers"][
                                  "Total-Interstate-Drivers"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title">
                            Intrastate Drivers &lt;100 Miles:
                          </div>
                          <div>
                            <span className="drivers_title_info">
                              {" "}
                              {
                                currentUser["Drivers"][
                                  "Intrastate-Drivers-<100-Miles"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title">
                            Intrastate Drivers 100+ Miles:
                          </div>
                          <div>
                            <span className="drivers_title_info">
                              {" "}
                              {
                                currentUser["Drivers"][
                                  "Intrastate-Drivers-100+-Miles"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title">
                            Total Intrastate Drivers:
                          </div>
                          <div>
                            <span className="drivers_title_info">
                              {" "}
                              {
                                currentUser["Drivers"][
                                  "Total-Intrastate-Drivers"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title">
                            Total Interstate & Intrastate Drivers:
                          </div>
                          <div>
                            <span className="drivers_title_info">
                              {
                                currentUser["Drivers"][
                                  "Total-Interstate-&-Intrastate-Drivers"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title">
                            CDL Employed Drivers:
                          </div>
                          <div>
                            <span className="drivers_title_info">
                              {" "}
                              {currentUser["Drivers"]["CDL-Employed-Drivers"]}
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title">
                            Monthly Average Leased Drivers:
                          </div>
                          <div>
                            <span className="drivers_title_info">
                              {" "}
                              {
                                currentUser["Drivers"][
                                  "Monthly-Average-Leased-Drivers"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="safety_and_drivers_information">
                  <div className="safety_information hide_info_type"></div>
                  <div className="drivers_information">
                    <div className="drivers_header">
                      <label style={{ textTransform: "uppercase" }}>
                        Equipment
                      </label>
                    </div>
                    <div className="drivers_info">
                      <div className="drivers_info_title">
                        <div className="drivers_row">
                          <div className="drivers_title">Fleet Size:</div>
                          <div>
                            <span className="drivers_title_info">
                              {" "}
                              {currentUser["Equipment"]["Fleet-Size"]}
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title">
                            Total Power Units:
                          </div>
                          <div>
                            <span className="drivers_title_info">
                              {" "}
                              {currentUser["Equipment"]["Total-Power-Units"]}
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title">
                            Total Tractors & Trucks:
                          </div>
                          <div>
                            <span className="drivers_title_info">
                              {" "}
                              {
                                currentUser["Equipment"][
                                  "Total-Tractors-&-Trucks"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="equipment_type">
                  <div className="equipment_type_titles">
                    <label>Equipment Type</label>
                    <div className="main_titles">
                      <label>Owned</label>
                      <label>Term Leased</label>
                      <label>Trip Leased</label>
                    </div>
                  </div>
                  <div className="equipment_type_info">
                    <div className="equipment_type_row">
                      <div className="equipment_type_title">Tractors</div>
                      <div className="equipment_type_title_info">
                        <span>
                          {currentUser["Equipment-Type"]["ownedTractors"]}
                        </span>
                        <span>
                          {currentUser["Equipment-Type"]["termTractors"]}
                        </span>
                        <span>
                          {currentUser["Equipment-Type"]["tripTractors"]}
                        </span>
                      </div>
                    </div>
                    <div className="equipment_type_row">
                      <div className="equipment_type_title">Trucks</div>
                      <div className="equipment_type_title_info">
                        <span>
                          {currentUser["Equipment-Type"]["ownedTrucks"]}
                        </span>
                        <span>
                          {currentUser["Equipment-Type"]["termTrucks"]}
                        </span>
                        <span>
                          {currentUser["Equipment-Type"]["tripTrucks"]}
                        </span>
                      </div>
                    </div>
                    <div className="equipment_type_row">
                      <div className="equipment_type_title">Trailers</div>
                      <div className="equipment_type_title_info">
                        <span>
                          {currentUser["Equipment-Type"]["ownedTrailers"]}
                        </span>
                        <span>
                          {" "}
                          {currentUser["Equipment-Type"]["termTrailers"]}
                        </span>
                        <span>
                          {currentUser["Equipment-Type"]["tripTrailers"]}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="side"></div>
                <div className="safety_and_drivers_information rmis">
                  <div className="safety_information">
                    <div className="safety_header">
                      <label style={{ textTransform: "uppercase" }}>
                        RMIS Requirements
                      </label>
                    </div>
                    <div className="safety_info">
                      <div className="safety_info_title">
                        <div className="safety_row">
                          <div className="safety_title safety_rule">Rule</div>
                          <div className="result-container">
                            <span className="result requirement_title_info">
                              Result
                            </span>
                            <span className="comment requirement_title_info">
                              Comment
                            </span>
                          </div>
                        </div>
                        <div className="safety_row">
                          <div className="safety_title">
                            Carrier Already Registered?
                          </div>
                          <div>
                            <span className="safety_title_info carrier_register">
                              {
                                currentUser["RMIS-REQUIREMENTS"][
                                  "Carrier-Already-Registered?"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="drivers_information rmis_information">
                    <div className="drivers_header">
                      <label style={{ textTransform: "uppercase" }}>
                        RMIS Information
                      </label>
                    </div>
                    <div className="drivers_info">
                      <div className="drivers_info_title">
                        <div className="drivers_row">
                          <div className="drivers_title">Company Name:</div>
                          <div>
                            <span className="drivers_title_info name_company">
                              {/* Rennhack Trucking LLC */}
                              {currentUser["U.S.-DOT-INFORMATION"][
                                "Legal-Name"
                              ].toLowerCase()}
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title">Address:</div>
                          <div>
                            <span className="drivers_title_info">
                              {
                                currentUser["U.S.-DOT-INFORMATION"]["Address"]
                                  .toLowerCase()
                                  .split(",")[0]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title ">City:</div>
                          <div>
                            <span className="drivers_title_info">
                              {" "}
                              {
                                currentUser["U.S.-DOT-INFORMATION"]["Address"]
                                  .toLowerCase()
                                  .split(",")[1]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title">State:</div>
                          <div>
                            <span className="drivers_title_info">
                              {" "}
                              {
                                currentUser["U.S.-DOT-INFORMATION"]["Address"]
                                  .toLowerCase()
                                  .split(",")[2]
                                  .split(" ")[1]
                              }
                            </span>
                          </div>
                        </div>
                        <div className="drivers_row">
                          <div className="drivers_title">Zip:</div>
                          <div>
                            <span className="drivers_title_info">
                              {
                                currentUser["U.S.-DOT-INFORMATION"]["Address"]
                                  .toLowerCase()
                                  .split(",")[2]
                                  .split(" ")[2]
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="verify_data">
                  <div className="verify_data_body">
                    <div className="verify_data_title">
                      <span>
                        RMIS HAS FOUND MATCHING INFORMATION FOR YOUR COMPANY.
                        PLEASE VERIFY THE DATA MATCHES YOUR COMPANY'S
                        INFORMATION
                      </span>
                    </div>
                    <div className="verify_data_option">
                      <div onClick={() => setIsChecked(true)}>
                        <input
                          type="radio"
                          id="sucsess"
                          name="carrier_type"
                          defaultChecked
                        />
                        <label htmlFor="sucsess">
                          Yes, this data matches my company.
                        </label>
                      </div>
                      <div onClick={() => setIsChecked(false)}>
                        <input type="radio" id="fail" name="carrier_type" />
                        <label htmlFor="fail">
                          No, this data does not match my company.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="step_buttons">
                  <div id="verify_submit_button">
                    <input
                      type="submit"
                      value="Go To Next Step"
                      onClick={() => navigate("/authorize")}
                    />
                  </div>
                  <input
                    className="goBackBtn"
                    type="submit"
                    value="Please click here to search again"
                    onClick={() => navigate("/v3")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default VerifyPage;
