import React, { useEffect, useRef, useState } from "react";
import "./interStateUsForm.css";
import { useNavigate } from "react-router-dom";
const InterStateUsForm = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState(false);

  const submitForm = (e) => {
    e.preventDefault() ;
    navigate();
  };

  const checkOption = (e) => {
    if (e.target.value.trim() === "") {
      setIsSelected(false);
    } else {
      setIsSelected(true);
    }
  };
  return (
    <div className="intrastateUs_form">
    <form onSubmit={submitForm}>
      <div className="intrastate_carrier">
        <div className="intrastate_carrier_form">
          <label className="intrastate_carrier_label">
            Intrastate Carrier:
          </label>
          <div className="intrastate_carrier_container"> 
            <div className="intrastate_carrier_choose">
              <select id="ddlDocketType" className="dropdown-toggle" defaultValue="" onChange={checkOption}>
                <option></option>
                <option defaultValue="AL">AL</option>
                <option defaultValue="AK">AK</option>
                <option defaultValue="AZ">AZ</option>
                <option defaultValue="AR">AR</option>
                <option defaultValue="CA">CA</option>
                <option defaultValue="CO">CO</option>
                <option defaultValue="CT">CT</option>
                <option defaultValue="DC">DC</option>
                <option defaultValue="DE">DE</option>
                <option defaultValue="FL">FL</option>
                <option defaultValue="GA">GA</option>
                <option defaultValue="HI">HI</option>
                <option defaultValue="ID">ID</option>
                <option defaultValue="IL">IL</option>
                <option defaultValue="IN">IN</option>
                <option defaultValue="IA">IA</option>
                <option defaultValue="KS">KS</option>
                <option defaultValue="KY">KY</option>
                <option defaultValue="LA">LA</option>
                <option defaultValue="ME">ME</option>
                <option defaultValue="MD">MD</option>
                <option defaultValue="MA">MA</option>
                <option defaultValue="MI">MI</option>
                <option defaultValue="MN">MN</option>
                <option defaultValue="MS">MS</option>
                <option defaultValue="MO">MO</option>
                <option defaultValue="MT">MT</option>
                <option defaultValue="NE">NE</option>
                <option defaultValue="NV">NV</option>
                <option defaultValue="NH">NH</option>
                <option defaultValue="NJ">NJ</option>
                <option defaultValue="NM">NM</option>
                <option defaultValue="NY">NY</option>
                <option defaultValue="NC">NC</option>
                <option defaultValue="ND">ND</option>
                <option defaultValue="OH">OH</option>
                <option defaultValue="OK">OK</option>
                <option defaultValue="OR">OR</option>
                <option defaultValue="PA">PA</option>
                <option defaultValue="RI">RI</option>
                <option defaultValue="SC">SC</option>
                <option defaultValue="SD">SD</option>
                <option defaultValue="TN">TN</option>
                <option defaultValue="TX">TX</option>
                <option defaultValue="UT">UT</option>
                <option defaultValue="VT">VT</option>
                <option defaultValue="VA">VA</option>
                <option defaultValue="WA">WA</option>
                <option defaultValue="WV">WV</option>
                <option defaultValue="WI">WI</option>
                <option defaultValue="WY">WY</option>
                <option defaultValue="PR">PR</option>
              </select>
              <input type="text" />
            </div>
          </div>
        </div>
        {isSelected ? (
          <>
            <div className="warning">
              <label>
                If you do not have a state-oriented intra number, please enter
                your DOT as your intra number.
              </label>
            </div>
            <div className="carrier_dot_number">
              <label className="dot_number_title">
                Please enter your U.S DOT Number:
              </label>
              <input type="text" />
            </div>
            <div className="dot_number_warning">
              <label>
                This state requires a U.S. DOT Number in addition to your
                intrastate permit.
              </label>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div id="interState_submit">
        <input type="submit" value="Go To Next Step" />
      </div>
    </form>
  </div>
    );
};

export default InterStateUsForm;
