import { createAsyncThunk } from "@reduxjs/toolkit";

export const getMCinfo = createAsyncThunk("users/getUsers", async (mccode) => {
  const result = await fetch(`http://127.0.0.1:8000/api/data-id/${mccode}/`);

  const data = await result.json();

  return data;
});

// export const getUsersData = createAsyncThunk("users/getUsersData", async () => {
//   const request = await fetch("http://localhost:8080/users");
//   return request.json();
// });

export const updateUsers = createAsyncThunk(
  "users/updateUsers",
  async (user) => {
    const response = await fetch(
      "https://jsalilogistics.rmlssecure.com/api/save/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }
    );

    const data = await response.json();
    return data;
  }
);
