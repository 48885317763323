import React from "react";
import "./socialWarning.css";
import { useDispatch, useSelector } from "react-redux";
import { selectSocial } from "../../store/slices/socialLogin/socialLoginSlice";
import { updateUsers } from "../../store/API";
import { useNavigate } from "react-router-dom";

const SocialWarning = () => {
  const { socialEmail } = useSelector(selectSocial);
  const { socialPassword } = useSelector(selectSocial);
  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const continueUser = () => {
    const newUser = {
      email: socialEmail,
      password: socialPassword,
    };

    dispatch(updateUsers(newUser));
    navigate("/authorize");
  };
  return (
    <>
      <div className="email-login-main-accounts">
        <div className="social-warning">
          <div className="social-warning_header">
            By continuing, Google will share your name, email address, language
            preference, and profile picture with Truckstop. See Truckstop’s{" "}
            <a href="https://truckstop.com/privacy-policy/" target="_blank">
              Privacy Policy
            </a>{" "}
            and Terms of Service.
          </div>
          <div className="manage-sign">
            You can manage Sign in with Google in your{" "}
            <a
              href="https://myaccount.google.com/connections#filter=4"
              target="_blank"
            >
              Google Account
            </a>
            .
          </div>
          <div className="warning-choice">
            <button
              className="warning-cancel_btn"
              onClick={() => navigate("/authorize")}
            >
              Cancel
            </button>
            <button
              className="warning-continue_btn"
              onClick={() => continueUser()}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialWarning;
