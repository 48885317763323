import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.jpg";
import "./navigation.css";
import { Link } from "react-router-dom";

const Navigation = () => {
  const [isResized, setIsResized] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const checkWindow = () => {
    const width = window.innerWidth;
    if (width <= 991) {
      setIsResized(true);
      setIsOpened(false);
    } else {
      setIsResized(false);
    }
  };

  useEffect(() => {
    checkWindow();
  }, []);
  window.addEventListener("resize", checkWindow);
  return (
    <>
      <header id="header" className={!isOpened ? "header" : ""}>
        <div className="header_menu">
          <div className="header_logo">
            <img src={logo} alt="" />
          </div>
          <div className="header_navigation">
            {isResized ? (
              <div
                className={`burger_menu ${isOpened ? "open" : ""}`}
                onClick={() => setIsOpened(!isOpened)}
              >
                <div className="line line1"></div>
                <div className="line line2"></div>
                <div className="line line3"></div>
              </div>
            ) : (
              <ul>
                <li>
                  <Link to="/">Registration</Link>
                </li>
                <li>
                  <Link to="/">Client Login</Link>
                </li>
                <li>
                  <Link to="/">Carrier Login</Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </header>
      {isResized ? (
        <div
          className={
            isOpened
              ? "burger_menu_body opened_burger_menu"
              : "burger_menu_body"
          }
        >
          <div className="burger_menu_nav">
            <div>
              <Link to="/">Registration</Link>
            </div>

            <div>
              <Link to="/">Client Login</Link>
            </div>

            <div>
              <Link to="/">Carrier Login</Link>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Navigation;
