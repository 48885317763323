import React, { useState } from "react";
import logo from "../../assets/truckstopLogo.png";
import "./registration.css";
import {
  CheckCircle,
  CheckedTrustDevice,
  ErrorPassword,
  HidePassword,
  SeePassword,
  SuccessPassword,
  UncheckCircle,
  UncheckedTrustDevice,
} from "../../utils/SVGIcons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeOAuthStatus } from "../../store/slices/registration/registrationSlice";

const Registration = ({ setCurrentStatus = { setCurrentStatus } }) => {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordVerifyType, setPasswordVerifyType] = useState("password");
  const [isCorrectPassword, setIsCorrectPassword] = useState(null);
  const [isCorrectVerifyPassword, setIsCorrectVerifyPassword] = useState(null);
  const [showedPasswordField, setShowedPasswordField] = useState(false);
  const [enoughLettersPassword, setEnoughLettersPassword] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const socialLogin = () => {
    dispatch(changeOAuthStatus(true));
    navigate("/oauth");
  };
  const checkPassword = (e) => {
    const text = e.target.value;
    setPassword(text);
    const hasUpper = /[A-Z]/.test(text);
    const hasLower = /[a-z]/.test(text);
    const isLongEnough = text.length >= 8;

    setEnoughLettersPassword(isLongEnough);
    setIsUpperCase(hasUpper);
    setIsLowerCase(hasLower);

    const shouldHideField = isLongEnough && hasUpper && hasLower;
    setShowedPasswordField(!shouldHideField);

    if (shouldHideField) {
      blurPassword();
    }

    focusPassword();
  };

  const blurPassword = () => {
    setShowedPasswordField(false);
    setIsCorrectPassword(isLowerCase && isUpperCase && enoughLettersPassword);
  };

  const focusPassword = () => {
    setShowedPasswordField(
      !(isLowerCase && isUpperCase && enoughLettersPassword)
    );
    setIsCorrectPassword(null);
  };

  const verifyPassword = (e) => {
    const text = e.target.value;

    if (text === password) {
      setIsCorrectVerifyPassword(true);
    } else {
      setIsCorrectVerifyPassword(false);
    }
  };

  return (
    <div className="registration">
      <div className="regisration_logo">
        <img src={logo} alt="" />
      </div>
      <div className="registration_title">
        <h2>Create Your Profile</h2>
      </div>
      <div className="registration_subtitle">
        Enter the required information below.
      </div>
      <form className="registration_form">
        <div className="registration_form_inputs">
          <div>
            <label htmlFor="reg_email">Email Address</label>
            <input type="text" id="reg_email" />
          </div>
          <div>
            <label htmlFor="reg_firstname" className="firstname">
              First Name
            </label>
            <input type="text" id="reg_firstname" />
          </div>
          <div>
            <label htmlFor="reg_lastname" className="lastname">
              Last Name
            </label>
            <input type="text" id="reg_lastname" />
          </div>
          <div className="password_field">
            <label htmlFor="login_password">Password</label>
            <input
              type={passwordType}
              id={isCorrectPassword ? "reg_password" : "login_password"}
              onFocus={(e) => focusPassword(e)}
              onBlur={(e) => blurPassword(e)}
              onChange={checkPassword}
            />
            <div className="password_icon">
              <div className="reg_icons">
                {passwordType === "password" ? (
                  <div
                    className="show_password"
                    onClick={() => setPasswordType("text")}
                  >
                    <SeePassword />
                  </div>
                ) : (
                  <div
                    className="show_password"
                    onClick={() => setPasswordType("password")}
                  >
                    <HidePassword />
                  </div>
                )}
                {isCorrectPassword === true ? (
                  <div className="password_status">
                    <SuccessPassword />
                  </div>
                ) : isCorrectPassword === false ? (
                  <div className="password_status">
                    <ErrorPassword />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="password_field">
            <label htmlFor="login_password">Verify Password</label>
            <input
              type={passwordVerifyType}
              id={!isCorrectVerifyPassword ? "reg_password" : "login_password"}
              onChange={(e) => verifyPassword(e)}
            />
            <div className="password_icon">
              <div className="reg_icons">
                {passwordVerifyType === "password" ? (
                  <div
                    className="show_password"
                    onClick={() => setPasswordVerifyType("text")}
                  >
                    <SeePassword />
                  </div>
                ) : (
                  <div
                    className="show_password"
                    onClick={() => setPasswordVerifyType("password")}
                  >
                    <HidePassword />
                  </div>
                )}
                {isCorrectVerifyPassword === true ? (
                  <div className="password_status">
                    <SuccessPassword />
                  </div>
                ) : isCorrectVerifyPassword === false ? (
                  <div className="password_status">
                    <ErrorPassword />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="login_submit_btn">
          <button className={isCorrectPassword ? "submit_reg_btn" : ""}>
            Submit
          </button>
        </div>
      </form>
      {showedPasswordField ? (
        <div className="password_check">
          <div className="password_conditions">
            <div className="password_condition-item">
              <div>
                {enoughLettersPassword ? <CheckCircle /> : <UncheckCircle />}
              </div>
              <div>8 Characters</div>
            </div>
            <div className="password_condition-item">
              <div>{isUpperCase ? <CheckCircle /> : <UncheckCircle />}</div>
              <div>One UPPERCASE Character</div>
            </div>
            <div className="password_condition-item">
              <div>{isLowerCase ? <CheckCircle /> : <UncheckCircle />}</div>
              <div>One lowercase Character</div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="login_another_way">
        <div className="google_or_registration">
          <button className="register_google" onClick={socialLogin}>
            Register with Google
          </button>
          <button
            onClick={() => {
              setCurrentStatus("LOGIN");
            }}
          >
            Cancle
          </button>
        </div>
      </div>
    </div>
  );
};

export default Registration;
