import React from "react";
import "./homePage.css";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="content_page">
        <div className="main_content">
          <div id="content">
            <div className="content_message">
              Welcome! Thank you for your interest in becoming an approved
              carrier for JIREH SALI LOGISTICS INC. We look forward to working
              with you.
            </div>
            <div className="content-requirements">
              <h4>To become a certified carrier, you must:</h4>
            </div>
            <ul className="outher_list">
              <li>
                Provide your MC/MX# or DOT# to begin registration. Intrastate
                carriers without a DOT# may provide an applicable state
                registration number to begin registration.
              </li>
              <li>Supply your company contact information.</li>
              <li>Submit an electronic W9 (have your TaxID available).</li>
              <li>Complete the Carrier Profile.</li>
              <li>Read and ACCEPT the Agreement(s).</li>
              <li>
                Provide a Certificate of Insurance that meets the insurance
                minimum requirements below. Certificates must be submitted from
                your insurance producer (agent)
                <ul className="inner_list">
                  <li>$100,000 Cargo Coverage</li>
                  <li>$1,000,000 Auto Coverage</li>
                  <li>Workers Compensation Coverage</li>
                  <li>$1,000,000 General Liability Each Occurrence Limit</li>
                  <li>$2,000,000 General Liability Aggregate Limit</li>
                </ul>
              </li>
              <li>
                If RMIS does not already have a copy of your certificate on
                file, we will request one for you from your insurance agent
                (producer).
              </li>
              <li>
                You will be required to meet the following requirements:
                <ul className="inner_list">
                  <li>Active Common or Contract Authority.</li>
                  <li>
                    Safety Rating must be Satisfactory, Conditional or None.
                  </li>
                  <li>Authority active for at least 90 days.</li>
                </ul>
              </li>
            </ul>
            <div id="content_submit">
              <input
                type="submit"
                value="Go To Next Step"
                onClick={() => navigate("/_c/std/reg/DOTLookupStdV3.aspx")}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
