import { Route, Routes } from "react-router-dom";
import Layout from "../layout/Layout";
import HomePage from "../pages/HomePage/HomePage";
import CarrierPage from "../pages/CarrierPage/CarrierPage";
import VerifyPage from "../pages/VerifyPage/VerifyPage";
import AuthorizePage from "../pages/AuthorizePage/AuthorizePage";
import SocialLogin from "../pages/SocialLogin/SocialLogin";
const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route
          path="/_c/std/reg/DOTLookupStdV3.aspx"
          element={<CarrierPage />}
        />
        <Route
          path="/_c/std/reg/DOTLookupStdV33.aspx"
          element={<VerifyPage />}
        />
      </Route>
      <Route path="/authorize" element={<AuthorizePage />} />
      <Route path="/oauth" element={<SocialLogin />} />
    </Routes>
  );
};

export default AppRouter;
