import React, { useState } from "react";
import logo from "../../assets/truckstopLogo.png";
import "./login.css";
import {
  CheckedTrustDevice,
  HidePassword,
  SeePassword,
  UncheckedTrustDevice,
} from "../../utils/SVGIcons";
import { useDispatch, useSelector } from "react-redux";
import { changeOAuthStatus, selectRegStatus } from "../../store/slices/registration/registrationSlice";
import { useNavigate } from "react-router-dom";

const Login = ({setCurrentStatus}) => {
  const [passwordType, setPasswordType] = useState("password");
  const [checkedDevice, setCheckedDevice] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const socialLogin = () => {
    dispatch(changeOAuthStatus(true));
    navigate('/oauth')
  }
  return (
    <div className="login">
      <div className="login_logo">
        <img src={logo} alt="" />
      </div>
      <div className="login_title">
        <h2>Login to your account</h2>
      </div>
      <div className="login_subtitle">Enter your details below.</div>
      <form className="login_form">
        <div className="login_form_inputs">
          <div>
            <label htmlFor="login_email">Your Email</label>
            <input type="text" id="login_email" />
          </div>
          <div className="password_field">
            <label htmlFor="login_password">Password</label>
            <input type={passwordType} id="login_password" />
            <div className="password_icon">
              {passwordType === "password" ? (
                <div onClick={() => setPasswordType("text")}>
                  <SeePassword />
                </div>
              ) : (
                <div onClick={() => setPasswordType("password")}>
                  <HidePassword />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="trust_device">
          {checkedDevice ? (
            <div onClick={() => setCheckedDevice(false)}>
              <CheckedTrustDevice />
            </div>
          ) : (
            <div onClick={() => setCheckedDevice(true)}>
              <UncheckedTrustDevice />
            </div>
          )}
          <label
            htmlFor="trust_device"
            onClick={() => setCheckedDevice(!checkedDevice)}
          >
            Trust this device for 30 days.
          </label>
              </div>
              <div className="login_submit_btn">
                  <button>Login</button>
              </div>
          </form>
          <div className="login_another_way">
              <p>OR</p>
              <div className="google_or_registration">
                  <button className="login_google" onClick={socialLogin}>Continue with Google</button>
                  <button onClick={() => setCurrentStatus('REGISTRATION')}>Register New User</button>
            </div>
          </div>
          <div className="login_footer">
              <p><a href="#a">Reset Password</a></p>
              <p><a href="#a">Get Support</a></p>
              <p><a href="#a">Manage How I Login</a></p>
          </div>
    </div>
  );
};

export default Login;
