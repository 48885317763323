import { createSlice } from "@reduxjs/toolkit";

const registrationSlice = createSlice({
  name: "registration",
  initialState: {
    registrationStatus: false,
    oauthStatus: false,
  },
  reducers: {
    changeRegistrationStatus: (state) => {
      state.registrationStatus = !state.registrationStatus;
    },
    changeOAuthStatus: (state, { payload }) => {
      state.oauthStatus = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const selectRegStatus = (state) => state.registration;
export const { changeRegistrationStatus, changeOAuthStatus } =
  registrationSlice.actions;
export const registrationReducer = registrationSlice.reducer;
