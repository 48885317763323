import React, { useEffect, useRef } from "react";
import "./interStateForm.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeRegistrationStatus } from "../../store/slices/registration/registrationSlice";
import { getMCinfo } from "../../store/API";
import { GetMCCode, selectUsers } from "../../store/slices/users/userSlice";
const InterStateForm = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const currentInputRef = useRef(null);
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selectUsers);

  useEffect(() => {
    if (currentInputRef.current) {
      currentInputRef.current.focus();
    }
  }, []);
  const checkInput = (e) => {
    const text = e.target.value;

    if (text !== "") {
      inputRef.current.classList.add("hide");
    } else {
      inputRef.current.classList.remove("hide");
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    const [MCCodeType, MCCode] = e.target;

    fetch(`https://jsalilogistics.rmlssecure.com/api/data-id/${MCCode.value}/`)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          console.log("errror");
        } else {
          // console.log('okay');
          // console.log(data);
          dispatch(GetMCCode(data));
          dispatch(changeRegistrationStatus());
          console.log("22");

          navigate("/_c/std/reg/DOTLookupStdV33.aspx");
        }
      })
      .catch((error) => console.log(error, "errror"));

    // console.log(getMCinfo);

    // dispatch(getMCinfo(MCCode.value)).then((result) => {
    //   console.log(getMCinfo.fulfilled.match(result).Drivers, 'test');

    //   if (getMCinfo.fulfilled.match(result)) {
    //     dispatch(changeRegistrationStatus());
    //     navigate("/verify");
    //   }else{
    //     return;
    //   }
    // })
  };

  return (
    <div className="interstate_form">
      <div className="docket">
        <form onSubmit={submitForm}>
          <div className="docket_form">
            <label>US Docket# (MC, FF, MX):</label>
            <div className="docket_choose">
              <select id="ddlDocketType" className="dropdown-toggle">
                <option defaultValue="MC">MC</option>
                <option defaultValue="FF">FF</option>
                <option defaultValue="MX">MX</option>
              </select>
              <input type="text" onChange={checkInput} ref={currentInputRef} />
            </div>
          </div>
          <div className="dot_number" ref={inputRef}>
            <label>US Dot Number:</label>
            <input type="text" />
          </div>
          <div id="interState_submit">
            <input type="submit" value="Go To Next Step" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default InterStateForm;
