import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectRegStatus } from "../../store/slices/registration/registrationSlice";
import { useNavigate } from "react-router-dom";
import {
  AnotherAccountIcon,
  GoogleIcon,
  GoogleIcons,
} from "../../utils/SVGIcons";
import logo from "../../assets/socialLogin.png";
import "./socialLogin.css";
import EmailField from "../../components/EmailField/EmailField";
import {
  changeCurrentStatus,
  changeSocialEmail,
  changeSocialPassword,
  changeSocialSubTitle,
  changeSocialTitle,
  selectSocial,
} from "../../store/slices/socialLogin/socialLoginSlice";
import PasswordField from "../../components/PasswordField/PasswordField";
import SocialWarning from "../../components/SocialWarning/SocialWarning";

const SocialLogin = () => {
  const { oauthStatus } = useSelector(selectRegStatus);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const { socialTitle } = useSelector(selectSocial);
  const { currentStatus } = useSelector(selectSocial);
  const { socialSubTitle } = useSelector(selectSocial);
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (oauthStatus) {
      setIsLoaded(true);
    } else {
      navigate("/authorize");
    }

    return () => {
      dispatch(changeSocialTitle("Choose an account"));
      dispatch(changeSocialSubTitle("DEFAULT"));
      dispatch(changeSocialEmail(""));
      dispatch(changeSocialPassword(""));
      dispatch(changeCurrentStatus("DEFAULT"));
    };
  }, []);

  if (!isLoaded) return;

  return (
    <>
      {isOpened ? (
        <div className="developer_info">
          <div className="developer_info_body">
            <div className="developer_info_header">
              <span className="developer_info_title">Developer info</span>
            </div>
            <div className="developer_info_main">
              <div className="developer_email">
                <span>Email: </span>
                <a href="mailto:shashankkapoor@truckstop.com" target="_blank">
                  shashankkapoor@truckstop.com
                </a>
              </div>
              <div className="developer_notification" jsname="DKOQHf">
                Choosing an account will redirect you to:
                https://auth.pingone.com
              </div>
            </div>
            <div className="developer_info_footer">
              <button onClick={() => setIsOpened(false)}>Got it</button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="social-login">
        <div className="social-login_body">
          <div className="social-login_header">
            <GoogleIcon />
            <div className="social-login_header_title">Sign in with Google</div>
          </div>
          <div className="social-login_divider"></div>
          <div className="social-login-main">
            <div className="social-login-main-info">
              <img src={logo} alt="" />
              <span className="social-login-main_title">{socialTitle}</span>
              {socialSubTitle === "DEFAULT" ? (
                <span className="social-login-main_subtitle">
                  to continue to{" "}
                  <button onClick={() => setIsOpened(true)}>Truckstop</button>
                </span>
              ) : (
                <span className="social-login-user_subtitle">
                  {socialSubTitle}
                </span>
              )}
            </div>

            {currentStatus === "DEFAULT" ? (
              <>
                <div className="social-login-main-accounts">
                  <div className="social-login-main-users">
                    <div
                      className="another-account"
                      onClick={() => {
                        dispatch(changeCurrentStatus("LOGIN_EMAIL"));
                        dispatch(changeSocialTitle("Sign in"));
                      }}
                    >
                      <div className="another-account-icon">
                        <AnotherAccountIcon />
                      </div>
                      <div className="social-login-user_info">
                        <div className="another-account-select">
                          Use another account
                        </div>
                      </div>
                    </div>
                    <div className="account_notification">
                      <div>
                        Before using this app, you can review Truckstop’s{" "}
                        <a
                          href="https://truckstop.com/privacy-policy/"
                          target="_blank"
                        >
                          privacy policy
                        </a>{" "}
                        and terms of service.
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </>
            ) : currentStatus === "LOGIN_EMAIL" ? (
              <EmailField />
            ) : currentStatus === "LOGIN_PASSWORD" ? (
              <PasswordField />
            ) : currentStatus === "LOGIN_WARNING" ? (
              <SocialWarning />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialLogin;
