import { createSlice } from "@reduxjs/toolkit";

const socialLoginSlice = createSlice({
  name: "social",
  initialState: {
    currentStatus: "DEFAULT",
      socialTitle: "Choose an account",
      socialSubTitle: 'DEFAULT',
      socialEmail: '',
      socialPassword: '',
  },
  reducers: {
    changeSocialTitle: (state, { payload }) => {
      state.socialTitle = payload;
      },
      changeSocialSubTitle: (state, { payload }) => {
          state.socialSubTitle = payload;
      },
    changeCurrentStatus: (state, { payload }) => {
      state.currentStatus = payload;
      },
      changeSocialEmail: (state, { payload }) => {
          state.socialEmail = payload;
      },
      changeSocialPassword: (state, { payload }) => {
          state.socialPassword = payload;
      }
  },
  extraReducers: (builder) => {},
});

export const selectSocial = (state) => state.social;
export const { changeSocialTitle,changeSocialSubTitle, changeCurrentStatus, changeSocialEmail, changeSocialPassword } = socialLoginSlice.actions;
export const socialReducer = socialLoginSlice.reducer;
