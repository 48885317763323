export const LoginIcon = () => {
  return (
    <svg
      fill="none"
      viewBox="0 0 18 18"
      height="18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "18px" }}
    >
      <path
        fill="#5C656A"
        d="M16 16H2V2H16V16ZM16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 0.89 17.1 0 16 0ZM13.5 13.25C13.5 11.75 10.5 11 9 11C7.5 11 4.5 11.75 4.5 13.25V14H13.5V13.25ZM9 9.25C9.59674 9.25 10.169 9.01295 10.591 8.59099C11.0129 8.16903 11.25 7.59674 11.25 7C11.25 6.40326 11.0129 5.83097 10.591 5.40901C10.169 4.98705 9.59674 4.75 9 4.75C8.40326 4.75 7.83097 4.98705 7.40901 5.40901C6.98705 5.83097 6.75 6.40326 6.75 7C6.75 7.59674 6.98705 8.16903 7.40901 8.59099C7.83097 9.01295 8.40326 9.25 9 9.25Z"
      ></path>
    </svg>
  );
};

export const RegistrationIcon = () => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 25"
      height="25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#586166"
        d="M16 18.1201H7V4.12012H16M11.5 22.1201C11.1022 22.1201 10.7206 21.9621 10.4393 21.6808C10.158 21.3995 10 21.0179 10 20.6201C10 20.2223 10.158 19.8408 10.4393 19.5595C10.7206 19.2782 11.1022 19.1201 11.5 19.1201C11.8978 19.1201 12.2794 19.2782 12.5607 19.5595C12.842 19.8408 13 20.2223 13 20.6201C13 21.0179 12.842 21.3995 12.5607 21.6808C12.2794 21.9621 11.8978 22.1201 11.5 22.1201ZM15.5 1.12012H7.5C6.83696 1.12012 6.20107 1.38351 5.73223 1.85235C5.26339 2.32119 5 2.95708 5 3.62012V20.6201C5 21.2832 5.26339 21.919 5.73223 22.3879C6.20107 22.8567 6.83696 23.1201 7.5 23.1201H15.5C16.163 23.1201 16.7989 22.8567 17.2678 22.3879C17.7366 21.919 18 21.2832 18 20.6201V3.62012C18 2.95708 17.7366 2.32119 17.2678 1.85235C16.7989 1.38351 16.163 1.12012 15.5 1.12012Z"
      ></path>
    </svg>
  );
};

export const ArrowRight = () => [
  <svg
    fill="none"
    viewBox="0 0 16 16"
    height="16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    key='saf'
  >
    <path
      fill="black"
      d="M0 7.04012V9.04012H12L6.5 14.5401L7.92 15.9601L15.84 8.04012L7.92 0.120117L6.5 1.54012L12 7.04012H0Z"
    ></path>
  </svg>,
];

export const SeePassword = () => {
  return (
    <svg
      className="mdi-icon  css-1jjrqoj e146bxl90"
      display="flex"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M2,5.27L3.28,4L20,20.72L18.73,22L15.65,18.92C14.5,19.3 13.28,19.5 12,19.5C7,19.5 2.73,16.39 1,12C1.69,10.24 2.79,8.69 4.19,7.46L2,5.27M12,9A3,3 0 0,1 15,12C15,12.35 14.94,12.69 14.83,13L11,9.17C11.31,9.06 11.65,9 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.18,14.08 20.79,15.88 19,17.19L17.58,15.76C18.94,14.82 20.06,13.54 20.82,12C19.17,8.64 15.76,6.5 12,6.5C10.91,6.5 9.84,6.68 8.84,7L7.3,5.47C8.74,4.85 10.33,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C12.69,17.5 13.37,17.43 14,17.29L11.72,15C10.29,14.85 9.15,13.71 9,12.28L5.6,8.87C4.61,9.72 3.78,10.78 3.18,12Z"></path>
    </svg>
  );
};

export const HidePassword = () => {
  return (
    <svg
      className="mdi-icon  css-1jjrqoj e146bxl90"
      display="flex"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"></path>
    </svg>
  );
};

export const UncheckedTrustDevice = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="rgb(182,2,7)"
      aria-hidden="true"
      className="is-default css-1r0mor5"
    >
      <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
    </svg>
  );
};

export const CheckedTrustDevice = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="rgb(182,2,7)"
      aria-hidden="true"
      className="is-default css-gb7zbp"
    >
      <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
    </svg>
  );
};

export const SuccessPassword = () => {
  return (
    <svg
      className="mdi-icon  css-1i3fbq e146bxl90"
      display="flex"
      data-id="is-valid-icon"
      width="20"
      height="20"
      fill="rgb(75,186,121)"
      viewBox="0 0 24 24"
    >
      <path d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"></path>
    </svg>
  );
};

export const ErrorPassword = () => {
  return (
    <svg
      className="mdi-icon  css-y0chu0 e146bxl90"
      display="flex"
      data-id="is-valid-icon"
      width="20"
      height="20"
      fill="rgb(163,19,0)"
      viewBox="0 0 24 24"
    >
      <path d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z"></path>
    </svg>
  );
};

export const CheckCircle = () => {
  return (
    <svg
      className="w-6 h-6 text-gray-800 dark:text-white"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="rgb(75,186,121)"
      viewBox="0 0 24 24"
    >
      <path
        fill-rule="evenodd"
        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
        clip-rule="evenodd"
      />
    </svg>
  );
};

export const UncheckCircle = () => {
  return (
    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="9"
        cy="9"
        r="7"
        fill="transparent"
        stroke="#686f77 "
        stroke-width="2"
      />
    </svg>
  );
};

export const GoogleIcon = () => {
  return (
    <svg
      className="NEH9Ef"
      xmlns="https://www.w3.org/2000/svg"
      width="20"
      height="24"
      viewBox="0 0 40 48"
      aria-hidden="true"
      jsname="jjf7Ff"
    >
      <path
        fill="#4285F4"
        d="M39.2 24.45c0-1.55-.16-3.04-.43-4.45H20v8h10.73c-.45 2.53-1.86 4.68-4 6.11v5.05h6.5c3.78-3.48 5.97-8.62 5.97-14.71z"
      ></path>
      <path
        fill="#34A853"
        d="M20 44c5.4 0 9.92-1.79 13.24-4.84l-6.5-5.05C24.95 35.3 22.67 36 20 36c-5.19 0-9.59-3.51-11.15-8.23h-6.7v5.2C5.43 39.51 12.18 44 20 44z"
      ></path>
      <path
        fill="#FABB05"
        d="M8.85 27.77c-.4-1.19-.62-2.46-.62-3.77s.22-2.58.62-3.77v-5.2h-6.7C.78 17.73 0 20.77 0 24s.78 6.27 2.14 8.97l6.71-5.2z"
      ></path>
      <path
        fill="#E94235"
        d="M20 12c2.93 0 5.55 1.01 7.62 2.98l5.76-5.76C29.92 5.98 25.39 4 20 4 12.18 4 5.43 8.49 2.14 15.03l6.7 5.2C10.41 15.51 14.81 12 20 12z"
      ></path>
    </svg>
  );
};

export const AnotherAccountIcon = () => {
  return (
    <svg
      aria-hidden="true"
      className="Qk3oof"
      fill="currentColor"
      focusable="false"
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10c5.52,0,10-4.48,10-10C22,6.48,17.52,2,12,2z M7.07,18.28 c0.43-0.9,3.05-1.78,4.93-1.78s4.51,0.88,4.93,1.78C15.57,19.36,13.86,20,12,20S8.43,19.36,7.07,18.28z M18.36,16.83 c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93,0.59-6.36,2.33C4.62,15.49,4,13.82,4,12c0-4.41,3.59-8,8-8c4.41,0,8,3.59,8,8 C20,13.82,19.38,15.49,18.36,16.83z"></path>
      <path d="M12,6c-1.94,0-3.5,1.56-3.5,3.5S10.06,13,12,13c1.94,0,3.5-1.56,3.5-3.5S13.94,6,12,6z M12,11c-0.83,0-1.5-0.67-1.5-1.5 C10.5,8.67,11.17,8,12,8c0.83,0,1.5,0.67,1.5,1.5C13.5,10.33,12.83,11,12,11z"></path>
    </svg>
  );
};
