import React, { useState } from "react";
import RMISLogo from "../../assets/logo-rmis.svg";
import "./authorizePage.css";
import { ArrowRight, LoginIcon, RegistrationIcon } from "../../utils/SVGIcons";
import Login from "../../components/Login/Login";
import Registration from "../../components/Registration/Registration";

const AuthorizePage = () => {
  const [currentStatus, setCurrentStatus] = useState("");
  return (
    <>
      <div className="authorize_site">
        <div className="authorize_page">
          <div className="authorize">
            {currentStatus.trim() === "" ? (
              <div className="authorize_body">
                <div className="authorize_container">
                  <div className="authorize_logo">
                    <img src={RMISLogo} alt="" />
                  </div>
                  <div className="authorize_title">
                    <h3>Let’s get you logged in.</h3>
                    <p>
                      Have you created a user profile? Create a FREE profile if
                      you haven’t already.
                    </p>
                  </div>
                  <div className="authorize_choice">
                    <div
                      className="have_profile"
                      onClick={() => setCurrentStatus("LOGIN")}
                    >
                      <div className="profile_heading">
                        <div className="profile_icon">
                          <LoginIcon />
                        </div>
                        <div className="profile_content">
                          <span className="profile_content_title">
                            I have a profile alredy
                          </span>
                          <span className="profile_content_subtitle">
                            Use my registered device to login.
                          </span>
                        </div>
                      </div>
                      <div className="arrow_icon">
                        <ArrowRight />
                      </div>
                    </div>
                    <div
                      className="have_profile"
                      onClick={() => setCurrentStatus("REGISTRATION")}
                    >
                      <div className="profile_heading">
                        <div className="profile_icon">
                          <RegistrationIcon />
                        </div>
                        <div className="profile_content">
                          <span className="reg_content_title">
                            I need to create a free profile
                          </span>
                          <span className="reg_content_subtitle">
                            Use my device to authenticate.
                          </span>
                        </div>
                      </div>
                      <div className="arrow_icon">
                        <ArrowRight />
                      </div>
                    </div>
                    <div className="create_account"></div>
                  </div>
                </div>
              </div>
            ) : currentStatus === "LOGIN" ? (
              <Login setCurrentStatus={setCurrentStatus} />
            ) : (
              <Registration setCurrentStatus={setCurrentStatus} />
            )}
          </div>

          <footer className="authorize_footer">
            <span>
              <a href="https://truckstop.com/terms-conditions/" target="_blank">
                Terms & Conditions
              </a>{" "}
              |{" "}
              <a href="https://truckstop.com/privacy-policy/" target="_blank">
                Privacy Policy
              </a>{" "}
              | © 2024 Truckstop. All rights reserved.
            </span>
          </footer>
        </div>
        <div className="authorize_info">
          <p className="side-panel-header">
            We're focused on removing fraud from our platform.
          </p>
          <div className="llp-info__divider"></div>
          <p className="lip-info__subheading">How it works</p>
          <p className="how-it-works-body">
            When you attempt to log in to your account from a new or
            unrecognized device, you will be asked to verify your identity.
          </p>
          <ul className="llp-info__list">
            <li>Push notification on your mobile device</li>
            <li>Security code via an authentication app</li>
            <li>Biometric input such as your face or fingerprint</li>
          </ul>
          <div>
            <a
              className="llp-info__support-link"
              target="_blank"
              href="https://truckstop.com/contact-us/"
            >
              Need more help? Get support.
            </a>
          </div>
          <div className="llp-info__divider"></div>
          <p className="lip-info__subheading">What customers are saying</p>
          <div className="llp-info__card">
            <p data-testid="testimonial-quote" className="llp-info__quote">
              “Working with Truckstop.com, you get to know people from the CEO
              all the way to IT. They’ve tailored their tools to work with us so
              they don’t have just one way of doing things.”
            </p>
          </div>
          <div className="lip_footer">
            <p className="llp-info__quote-attribution">Zachary Green, Ryder</p>
            <img
              alt="Person Icon"
              src="https://truckstop-mdc-components.web.app/images/icon_account.svg"
            ></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorizePage;
