import { configureStore } from "@reduxjs/toolkit";
import { registrationReducer } from "./slices/registration/registrationSlice";
import { socialReducer } from "./slices/socialLogin/socialLoginSlice";
import { usersReducer } from "./slices/users/userSlice";

const store = configureStore({
    reducer: {
        registration: registrationReducer,
        social: socialReducer,
        users: usersReducer,
    },
});


export default store;