// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#footer {
  width: 100%;
  height: 150px; 
  display: flex;
  justify-content: center; 
  flex-direction: column;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 14px;
}

@media (max-width: 767px) {
  #footer span {
    text-align: center;
  }

  .footer_content {
    width: 94%;
    margin-left: 3%;
  }
  .privacy {
    margin-top: 0px !important;
  }
}

.footer_title {
  font-size: 14px;
  margin-top: -120px !important;
}

a {
  text-decoration: none;
  color: #14aaf5;
}

.status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #2ecc71;
}

.rmis-status {
  font-size: 14px;
  color: #14aaf5;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,+CAA+C;EAC/C,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,UAAU;IACV,eAAe;EACjB;EACA;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,cAAc;EACd,eAAe;AACjB","sourcesContent":["#footer {\n  width: 100%;\n  height: 150px; \n  display: flex;\n  justify-content: center; \n  flex-direction: column;\n  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);\n  text-align: center;\n  font-size: 14px;\n}\n\n@media (max-width: 767px) {\n  #footer span {\n    text-align: center;\n  }\n\n  .footer_content {\n    width: 94%;\n    margin-left: 3%;\n  }\n  .privacy {\n    margin-top: 0px !important;\n  }\n}\n\n.footer_title {\n  font-size: 14px;\n  margin-top: -120px !important;\n}\n\na {\n  text-decoration: none;\n  color: #14aaf5;\n}\n\n.status-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n}\n\n.status-dot {\n  width: 10px;\n  height: 10px;\n  border-radius: 50%;\n  background-color: #2ecc71;\n}\n\n.rmis-status {\n  font-size: 14px;\n  color: #14aaf5;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
