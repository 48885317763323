import React, { useState } from "react";
import "./carrierPage.css";
import Footer from "../../components/Footer/Footer";
import InterStateForm from "../../components/InterStateForm/InterStateForm";
import InterStateUsForm from "../../components/InterStateUsForm/InterStateUsForm";
import IntraCanada from "../../components/IntraCanada/IntraCanada";

const CarrierPage = () => {
  const [currentChoice, setCurrentChoice] = useState("");
  return (
    <>
      <div className="carrier_page">
        <div className="carrier_main">
          <div className="carrier_prequalification">
            <span>Carrier Prequalification</span>
          </div>
          <div id="carrier_content">
            <label id="selectPlaceholder">
              Please select <strong> ONE </strong> carrier identifier for
              registration.
            </label>
            <div className="carrier_choice">
              <div onClick={() => setCurrentChoice("interState")}>
                <input type="radio" id="interstate" name="carrier_type" />
                <label htmlFor="interstate">
                  Interstate (hauling across state lines)
                </label>
              </div>
              <div onClick={() => setCurrentChoice("intraStateUS")}>
                <input type="radio" id="intrastate" name="carrier_type" />
                <label htmlFor="intrastate">
                  Intrastate US (hauling within a single state only)
                </label>
              </div>
              <div onClick={() => setCurrentChoice("intraCanada")}>
                <input type="radio" id="intra_canada" name="carrier_type" />
                <label htmlFor="intra_canada">
                  Intra Canada (hauling within Canada only)
                </label>
              </div>
            </div>
            {currentChoice === "interState" ? (
              <InterStateForm />
            ) : currentChoice === "intraStateUS" ? (
              <InterStateUsForm />
            ) : currentChoice === "intraCanada" ? (
              <IntraCanada />
            ) : (
              <div id="carrier_submit">
                <input type="submit" value="Go To Next Step" />
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CarrierPage;
