import { createSlice } from "@reduxjs/toolkit";
import { getMCinfo, updateUsers } from "../../API";

const userSlice = createSlice({
  name: "users",
  initialState: {
    usersData: [],
    currentUser: null,
    isError: false,
  },
  reducers: {
    GetMCCode(state, action) {
      // state.currentUser = action.payload
      if (action.payload.error) {
        state.currentUser = null;
        state.isError = true;
      } else {
        state.currentUser = action.payload;
        state.isError = false;
      }
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(getMCinfo.fulfilled, (state, { payload }) => {
    //   console.log(payload, "asdasa");

    //   state.currentUser = payload;
    // });
    builder.addCase(updateUsers.fulfilled, (state, { payload }) => {
      state.usersData.push(payload);
    });
  },
});

export const selectUsers = (state) => state.users;
export const { GetMCCode } = userSlice.actions;
export const usersReducer = userSlice.reducer;
