import React, { useEffect, useRef, useState } from "react";
import "./intraCanada.css";
import { useNavigate } from "react-router-dom";

const IntraCanada = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    navigate();
  };

  const checkOption = (e) => {
    if (e.target.value.trim() === "") {
      setIsSelected(false);
    } else {
      setIsSelected(true);
    }
  };

  return (
    <div className="intraCanada_form">
      <form onSubmit={submitForm}>
        <div className="intracanada">
          <div className="input_container">
            <div className="row">
              <div className="cvor_form">
                <label className="cvor">CVOR:</label>
                <div>
                  <span>ON</span>
                  <input type="text" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="cvor_form nir_form">
                <label className="nir">NIR:</label>
                <div>
                  <span className="qc">QC</span>
                  <input type="text" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="cvor_form nsc_form">
                <label className="nsc">NSC:</label>
                <div>
                  <select className="nsc_select dropdown-toggle">
                    <option></option>
                    <option defaultValue="AB">AB</option>
                    <option defaultValue="BC">BC</option>
                    <option defaultValue="MB">MB</option>
                    <option defaultValue="NB">NB</option>
                    <option defaultValue="NL">NL</option>
                    <option defaultValue="NT">NT</option>
                    <option defaultValue="NS">NS</option>
                    <option defaultValue="NU">NU</option>
                    <option defaultValue="PE">PE</option>
                    <option defaultValue="SK">SK</option>
                    <option defaultValue="YT">YT</option>
                  </select>
                  <input type="text" className="nsc_input" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="interState_submit">
          <input type="submit" value="Go To Next Step" />
        </div>
      </form>
    </div>
  );
};

export default IntraCanada;
